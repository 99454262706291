<template>
    <section>
      <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="150px" style="width:95%;overflow:auto">
        <el-form-item label="Mac地址" prop="macId">
          <el-input placeholder="请输入Mac地址" v-model="form.macId" :disabled="row ? true : false"></el-input>
        </el-form-item>
        <el-form-item label="所属商户" prop="merchantId" v-if="userInfo.roleLevel < 5 && ( (row && !row.merchantId) || !row)">
          <el-select v-model="form.merchantId" placeholder="请选择所属商户" @change="chooseMerchant">
            <el-option
              v-for="item in managerUnitOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="安装小区" prop="unitId"  v-if="userInfo.roleLevel <= 5">
          <el-select filterable remote v-model="form.unitId" clearable placeholder="请输入小区名称查询" :remote-method="getNieghbourList">
            <el-option
              v-for="item in neighbourhoodList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="小区内具体安装位置" prop="address">
          <el-input placeholder="请填写具体安装位置" v-model="form.address"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="close('form')">取消</el-button>
          <el-button type="primary" @click="handleSubmit('form')" :plain='true'>提交</el-button>
        </el-form-item>
      </el-form>
    </section>
</template>

  <script>
  import { addRouter,editRouter,getNeighbourhoodList,getMerchantList } from "@/api/public.js";
  export default {
    props:['row'], // 父组件携带参数
    data() {
      return {
        form:{ // 表单数据
          unitId:'',
          address: "",
          macId: "",
        },
        neighborList:[], // 小区列表
        neighbourhoodList:[], // 小区选项
        rules: { // 表单验证
          macId:[
            { required: true, message: 'Mac地址不得为空', trigger: 'blur', }
          ],
          unitId:[
            { required:true,message:'安装小区不得为空',trigger:"blur"}
          ],
          address:[
            { required:true,message:'具体安装位置不得为空',trigger:"blur"}
          ],
        },
        userInfo:JSON.parse(localStorage.getItem('userInfo')),
        managerUnitOptions:[], // 商户选项
      };
    },
  
    components: {},
  
    computed: {},
  
    mounted() {
      this.form = this.row ? {...this.row} : this.form;
      this.getNieghbourList("");
      this.getMerchantOptions();
    },
  
    methods: {
      // 获取小区列表
      getNieghbourList(e){
        let data = {
          pageNo:1,
          pageSize:1000,
          param:{
            name:e,
            merchantId:this.form.merchantId,
          }
        }
        getNeighbourhoodList(data).then(res=>{
          if(res.success){
            this.neighbourhoodList = res.data.list;
          }
        })
      },
      // 获取商户列表
      async getMerchantOptions(){
        let data = {
          pageNo:1,
          pageSize:1000,
          param:{
            name:'',
          }
        }
        await getMerchantList(data).then(res=>{
          if(res.success){
            this.managerUnitOptions = res.data.list;
          }else{
            this.managerUnitOptions = [];
          }
        })
      },
      // 选择商户、小区
      chooseMerchant(e){
        this.form.merchantId = e;
        this.getNieghbourList();
      },
      // 关闭弹窗
      close(form){
         this.$emit('close');
      },
      // 新增
      async add(){
        await addRouter({param:this.form}).then((res) => {
          if (res.success) {
            this.$message({
              message: "提交成功",
              type: "success",
            });
            setTimeout(() => {
              this.$emit("close");
              this.init();
            }, 1000);
          }else{
            this.$message({
              message: "提交失败，请重试",
              type: "fail",
            });
          }
        })
      },
      // 编辑
      async edit(){
        await editRouter({param:this.form}).then((res) => {
          if (res.success) {
            this.$message({
              message: "提交成功",
              type: "success",
            });
            setTimeout(() => {
              this.$emit("close");
              this.init();
            }, 1000);
          }else{
            this.$message({
              message: res.msg,
              type: "fail",
            });
          }
        })
      },
      // 提交
      handleSubmit(form) {
        if(this.userInfo.roleLevel >= 10){
          this.form.unitId = this.userInfo.unitId;
        }
         this.$refs["form"].validate(valid => {
            if (valid) {
                this.$confirm('确认提交吗？', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(()=>{
                  if(this.row){
                    this.edit();
                  }else{
                    this.add();
                  }
                })
            }else{
              return false;
            }
        });
      },
    }
  };
  </script>
  <style lang="scss" scoped>
  // 防止地图自动完成的对象被遮挡
  .tangram-suggestion {
      z-index: 9999 !important;
    }
   .el-drawer{
     overflow:auto !important;
   }
   /deep/ .el-form-item__content{
    display: flex;
   }
   .map{
    border: 1px solid #dcdfe6;
    background: #fff;
    border-radius: 4px;
    width: 80px;
   }
   .avatar-uploader .el-upload {
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      border: 1px dashed #ccc;
      border-radius: 50%;
      overflow:hidden;
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  </style>
  