<template>
  <div class="outContainer">
    <!-- 筛选条件栏 -->
    <div class="search">
      <el-cascader class="each" placeholder="所属商户 / 所属小区"
        clearable
        v-model="unitId"
        :options="managerUnitOptions"
        @change="chooseMerchant"
        :props="props"
        v-if="userInfo.roleLevel < 5"
      ></el-cascader>
      <el-select class='each' placeholder="安装小区" v-model="searchInfo.param.unitId" clearable v-if="userInfo.roleLevel >= 5 && userInfo.roleLevel < 10">
        <el-option
          v-for="item in unitOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
      <el-input class="each" placeholder="小区内具体安装位置" v-model="searchInfo.param.address">
      </el-input>
      <el-input class="each" placeholder="路由器Mac地址" v-model="searchInfo.param.macId">
      </el-input>
      <el-button size="mini" type="primary" icon="el-icon-search" @click="handleSearch('search')">搜索</el-button>
      <el-button size="mini" type="primary" icon="el-icon-close" @click="handleSearch('clear')">清空搜索条件</el-button>
      <el-button size="mini" @click="openDrawer(null)">新增</el-button>
    </div>
    <!-- 数据表格 -->
    <div class="tableContainer">
      <el-table
      :data="tableList"
      :row-class-name="getRowClass"
      height="100%"
      style="width: 100%;">
        <!-- 扩展项：WiFi开关列表 -->
        <el-table-column type="expand">
          <template slot-scope="scope">
            <el-table
            :data="scope.row.switchDeviceVOList"
            :header-cell-style="{ background:'#ddd' }"
            border
            >
              <el-table-column align="center" label="WiFi开关编号" prop="id">
              </el-table-column>
              <el-table-column align="center" label="开关名称" prop="name">
              </el-table-column>
              <el-table-column align="center" label="在线状态" prop="online">
                <template slot-scope="props">
                  {{ props.row.online == 1 ? '在线' : ( props.row.online == 2 ? '离线' : '--' )}}
                </template>
              </el-table-column>
              <el-table-column align="center" label="开启状态" prop="status">
                <template slot-scope="props">
                  <el-switch
                    :disabled="props.row.online == 2"
                    :value="props.row.status == 1 ? true : false"
                    active-color="#13ce66"
                    @change="changeStatus(props.row)">
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column align="center" label="版本" prop="version">
                <template slot-scope="props">
                  <p>硬件版本：{{ props.row.hardWareVersion ? props.row.hardWareVersion : '--' }}</p>
                  <p>软件版本：{{ props.row.version ? props.row.version : '--' }}</p>
                </template>
              </el-table-column>
              <el-table-column align="center" label="创建时间" prop="createTime">
              </el-table-column>
              <el-table-column align="center" label="操作" fixed="right" width="200">
                <template slot-scope="props">
                  <p style="margin-bottom: 5px;">
                    <el-button size="mini" @click="checkCommunicate(props.row)">通讯记录</el-button>
                    <el-button size="mini" @click="handleVersion(props.row)">版本控制</el-button>
                  </p>
                  <p>
                    <el-button size="mini" @click="openWifiDrawer(props.row,'edit')">编辑</el-button>
                    <el-button size="mini" @click="delWifi(props.row)">删除</el-button>
                  </p>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <af-table-column align="center" label="Mac地址" prop="macId">
        </af-table-column>
        <af-table-column align="center" label="安装小区" prop="unitName">
        </af-table-column>
        <af-table-column align="center" label="具体安装位置" prop="address">
        </af-table-column>
        <af-table-column align="center" label="创建时间" prop="createTime">
        </af-table-column>
        <el-table-column align="center" label="操作" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" @click="openWifiDrawer(scope.row,'add')">新增WiFi开关</el-button>
            <p style="margin-top:5px">
              <el-button size="mini" @click="openDrawer(scope.row)">编辑</el-button>
              <el-button size="mini" @click="handleDel(scope.row)">删除</el-button>
            </p>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <Pagination v-bind:child-msg="searchInfo" @callFather="callFather"></Pagination>
    <!-- 新增、编辑 -->
    <el-drawer :title="row ? '编辑' : '新增'"
      :visible.sync="addDrawer"
      size="50%">
      <add v-if="addDrawer" @close="handleClose"  ref='newForm' :row="row"></add>
    </el-drawer>
    <!-- 新增、编辑WiFi开关 -->
    <el-drawer :title="row && row.btnType == 'edit' ? '编辑WiFi开关' : '新增WiFi开关'"
      :visible.sync="addWifiDrawer"
      size="50%">
      <addWifi v-if="addWifiDrawer" @close="handleClose"  ref='newForm' :row="row"></addWifi>
    </el-drawer>
    <!-- 版本控制 -->
    <el-dialog title="版本控制" :visible.sync="equipVersionVisible" >
      <p class="dec" v-if="this.versionForm">
          更新状态：
          <span v-if="versionForm.targetVersion">{{ versionForm.fromVersion}} - {{ versionForm.targetVersion }}</span>
          <span v-if="versionForm.targetVersion">{{ versionForm.status == 1 ? "待更新" : (versionForm.status == 2 ? "更新中" : (versionForm.status == 3 ? "更新成功" : "更新失败")) }}</span>
          <span v-if="!versionForm.targetVersion">未更新</span>
      </p>
      <el-form :model="versionForm" v-if="versionForm.status != 2">
        <el-form-item label="版本号" label-width="100px">
          <el-select remote filterable v-model="versionForm.softwareId" :remote-method="searchVersion" placeholder="请选择" style="width: 100%;">
            <el-option
               v-for="item in softVersionOptions"
               :key="item.id"
               :label="item.version"
               :value="item.id"
              >
             </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" v-if="versionForm.status != 2">
        <el-button @click="equipVersionVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmUpdateVersion">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination.vue';
import add from './add.vue';
import addWifi from './addWifi.vue';
import { getNeighbourhoodList,getRouterList,delRouter,delWifiSwitch,lockSwitch,deviceUpgrade,latestUpgradeRecord,getListSoftwareVersion,getMerchantList} from "@/api/public.js";
import { timeChange } from '@/api/publicFun.js';
export default {
  data() {
    return {
      userInfo:JSON.parse(localStorage.getItem('userInfo')),
      tableList:[], // 表格数据
      unitOptions:[], // 小区选项列表
      searchInfo:{ // 搜索条件
        pageNo:1,
        pageSize:10,
        total:0,
        param:{
          macId:"",
          address:"",
          unitId:"",
        }
      },
      addDrawer:false, // 控制新增、编辑路由器弹框是否显示
      addWifiDrawer:false, // 控制新增、编辑WiFi开关弹框是否显示
      row:null, // 当前操作数据列详细信息
      versionOptions:[],
      softVersionOptions:[],// 软件版本号列表
      equipVersionVisible:false, // 版本弹框是否显示
      versionForm:{
        Version:'',
        softwareId:"",
      },
      managerUnitOptions:[], // 所属商户、所属小区选项
      unitId:"",
      props: { // 所属商户、小区 联动选择框配置
        value:'id',
        label:'name',
        lazy: true,
        checkStrictly:true,
        lazyLoad (node, resolve) {
          const { level } = node;
          setTimeout(() => {
            let data = {
              pageNo:1,
              pageSize:1000,
              param:{
                name:null,
                merchantId:node.value,
              }
            }
            if(level == 1){
              getNeighbourhoodList(data).then(res=>{
              // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                let nodes = [];
                res.data.list.forEach(element=>{
                    nodes.push({
                      id: element.id,
                      name: element.name,
                      leaf: level >= 1,
                      children:null,
                    })
                })
                // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                resolve(nodes);
              })
              console.log(node,908)
            }
          }, 1000);
        }
      },
    }
  },

  components: {
    Pagination,
    add,
    addWifi,
  },

  computed: {},

  mounted() {
    this.userInfo.roleLevel < 5 ? this.getMerchantOptions() : this.getNieghbourList();
    this.init();
  },

  methods: {
    // 获取小区列表
    async getNieghbourList(){
      let data = {
        pageNo:1,
        pageSize:1000,
        param:{
          name:null,
        }
      }
      await getNeighbourhoodList(data).then(res=>{
        if(res.success){
          this.unitOptions = res.data.list;
        }
      })
    },
    // 获取商户列表
    async getMerchantOptions(){
      let data = {
        pageNo:1,
        pageSize:1000,
        param:{
          name:'',
        }
      }
      await getMerchantList(data).then(res=>{
        if(res.success){
          this.managerUnitOptions = res.data.list;
          this.managerUnitOptions.forEach(element=>{
            element.children = [];
          })
        }else{
          this.managerUnitOptions = [];
        }
      })
    },
    // 选择商户、小区
    chooseMerchant(e){
      this.searchInfo.param.merchantId = e[0] ? e[0] : null;
      this.searchInfo.param.unitId = e[1] ? e[1] : null;
    },
    // 获取表格数据
    async init(){
      await getRouterList(this.searchInfo).then(res=>{
        if(res.success && res.data){
          this.tableList = res.data.list;
          this.tableList.forEach(element => {
            element.createTime = timeChange(element.createTime,'seconds');
            // 判断该路由器下是否存在WiFi开关
            if(element.switchDeviceVOList && element.switchDeviceVOList.length > 0){
              let wifiList = element.switchDeviceVOList;
              wifiList.forEach(ele => {
                ele.createTime = timeChange(ele.createTime,'seconds')
              })
            }
          });
          this.searchInfo.total = res.data.total;
        }else{
          this.tableList = [];
          this.searchInfo.total = 0;
        }
      })
    },
    // 判断当前行是否有WiFi开关数据或者根据实际情况设置
    getRowClass(row){
      if(!row.row.switchDeviceVOList ||row.row.switchDeviceVOList.length == 0){
        return 'row-expand-cover';
      }
    },
    // 搜索
    handleSearch(type){
      if(type == 'clear'){
        this.searchInfo.param = {
          macId:"",
          address:"",
          unitId:"",
          merchantId:null,
        }
        this.unitId = [];
      }
      this.searchInfo.pageNo = 1;
      this.init();
    },
    // 打开、关闭wifi开关
    changeStatus(e){
      this.$confirm('确认进行该操作吗？').then(async () => {
        const loading = this.$loading({
          lock: true,
          text: '操作中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        let data = {
          param:{
            switchLock:e.status == 1 ? 0 : 1,
            wifiId:e.id,
          }
        }
        await lockSwitch(data).then(res=>{
          if(res.success && res.data.result){
            this.$message({
              message:'操作成功',
              type:'success'
            })
          }else{
            this.$message({
              message:'操作失败',
              type:'fail'
            })
          }
          this.init();
          loading.close();
        })
      })
    },
    // 查看WiFi开关通讯记录
    checkCommunicate(row){
      this.$router.push({path:'/device/wifiCommunication',query:{id:row.id}})
    },
    // 打开侧边弹框
    openDrawer(row){
      this.row = row;
      this.addDrawer = true;
    },
    // 打开新增、编辑wifi开关弹框
    openWifiDrawer(row,type){
      this.row = row;
      this.row.btnType = type;
      this.addWifiDrawer = true;
    },
    // 删除路由器
    handleDel(row){
      this.$confirm('确认删除吗？').then(async () => {
        await delRouter({ param:row.macId }).then(res=>{
          if(res.success){
            this.$message({
              message:'操作成功',
              type:'success'
            })
          }else{
            this.$message({
              message:res.msg,
              type:'fail'
            })
          }
          this.init();
        })
      })
    },
    // 删除WiFi开关
    delWifi(row){
      this.$confirm('确认删除吗？').then(async () => {
        await delWifiSwitch({ param:row.id }).then(res=>{
          if(res.success){
            this.$message({
              message:'操作成功',
              type:'success'
            })
          }else{
            this.$message({
              message:res.msg,
              type:'fail'
            })
          }
          this.init();
        })
      })
    },
    // 关闭弹框
    handleClose(){
      this.addDrawer = false;
      this.addWifiDrawer = false;
      this.row = null;
      this.init();
    },
    // 打开版本弹框
    handleVersion(row){
      this.row = row;
      this.getLatestRecord();
      this.searchVersion("");
    },
    // 获取最近一条版本更新记录
    getLatestRecord(){
      let data = {
        deviceId: this.row.id,
        deviceType: 4
      }
      latestUpgradeRecord({param:data}).then(res=>{
        if(res.success){
          this.versionForm = res.data || this.row;
          this.versionForm.nowVersion
        }
      })
    },
    // 搜索软件版本号
    async searchVersion(e){
      let data = {
        pageNo:1,
        pageSize:10,
        param:{
            softwareVersion:e,
            hardwareVersion:this.row.hardWareVersion,
            status:true,
            deviceType:4,
        }
      }
      await getListSoftwareVersion(data).then(res=>{
        if(res.success){
          this.softVersionOptions = res.data.list;
          if(this.softVersionOptions.length == 1){
            this.versionForm.softwareId = this.softVersionOptions[0].id;
          }
          this.equipVersionVisible = true;
        }else{
          this.$message({
            message:res.msg,
            type:'fail'
          })
        }
      })
    },
    // 确认升级版本
    confirmUpdateVersion(){
      this.$confirm('确认进行该操作吗？').then(async () => {
        let data = {
          deviceId: this.row.id,
          deviceType: 4,
          softwareId: this.versionForm.softwareId,
        }
        await deviceUpgrade({param:data}).then(res=>{
          if(res.success){
            this.$message({
              message:'指令发送成功！',
              type:'success'
            })
            this.equipVersionVisible = false;
            this.init();
          }else{
            this.$message({
              message:res.msg,
              type:'fail'
            })
          }
        })
      })
      
    },
    // 分页事件
    callFather(param){
      this.searchInfo.pageNo = param.currentPage;
      this.init();
    },
  },
};
</script>
<style scoped lang="scss">
/deep/ .el-table .row-expand-cover .cell .el-table__expand-icon {
  display: none;
}
/deep/ .el-loading-spinner .el-loading-text{
  color: #fff !important;
}
</style>
